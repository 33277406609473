// assets
import DashboardIcon from '@mui/icons-material/Dashboard';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TableChartIcon from '@mui/icons-material/TableChart';
// constant
const icons = {
    DashboardIcon: DashboardIcon,
    IconChat: MarkEmailUnreadOutlinedIcon,
    IconSSKPI: EqualizerOutlinedIcon,
    IconKPI: SignalCellularAltOutlinedIcon,
    IconVoyage: AddchartOutlinedIcon,
    IconChart: InsightsOutlinedIcon,
    IconMinutes: BorderColorOutlinedIcon,
    IconTask: PostAddOutlinedIcon,
    IconReport: SummarizeOutlinedIcon,
    IconEvent: CalendarMonthOutlinedIcon,
    IconEfile: FolderSpecialOutlinedIcon,
    Icontariff: TrendingUpIcon,
    IconEAir: TableChartIcon
};



export const SSMenu = {
    id: 'Menu',
    type: 'group',
    children: [
        {
            id: 'DashboardIcon',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/division',
            icon: icons['DashboardIcon']
        },

        {
            id: 'ssk',
            title: 'S.S.K.P.I',
            type: 'item',
            url: '/dashboard/division/sskpi',
            icon: icons['IconSSKPI']
        },
        {
            id: 'kpi',
            title: 'K.P.I',
            type: 'item',
            url: '/dashboard/division/kpi',
            icon: icons['IconKPI']
        },
        {
            id: 'air',
            title: 'Air Freight',
            type: 'item',
            url: '/dashboard/division/airfreight',
            icon: icons['IconEAir']
        },
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            url: '/dashboard/division/reports',
            icon: icons['IconReport']
        },
        {
            id: 'minutes',
            title: 'Minutes',
            type: 'item',
            url: '/dashboard/division/minutes',
            icon: icons['IconMinutes']
        },
        {
            id: 'chat',
            title: 'Chat',
            type: 'item',
            url: '/dashboard/division/chat',
            icon: icons['IconChat']
        },
        {
            id: 'event',
            title: 'Event',
            type: 'item',
            url: '/dashboard/division/events',
            icon: icons['IconEvent']
        },
        {
            id: 'task',
            title: 'Task',
            type: 'item',
            url: '/dashboard/division/tasks',
            icon: icons['IconTask']
        },
        {
            id: 'file',
            title: 'folders',
            type: 'item',
            url: '/dashboard/division/folders',
            icon: icons['IconEfile']
        },
        {
            id: 'data',
            title: 'Analytics',
            type: 'item',
            url: '/dashboard/division/kpi-analytics',
            icon: icons['IconChart']
        }
    ]
};
