import React from 'react';
import { Typography, Grid, Box, Container, Button,Stack } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import error404 from 'assets/images/ErrorPages/404Illustration.svg';
import useStyles from 'useStyles/error404';

function ErrorPage404() {
    const error = useRouteError();
    const classes = useStyles();

    const routeChange = () => {
        if (!error.message) {
            const path = `/auth/login`;
            window.location.href = path;
        } else {
            window.location.reload();
        }
    };

    return (
        <Container className={classes.container}>
            <Grid container spacing={4}>
                <Grid alignItems="center" item xs={12} sm={6} md={6} lg={6} style={{ paddingLeft: 50 }}>
                    <Stack direction="column" spacing={2} alignItems="center">
                        <Typography className={classes.errorCode}>Opps!!!</Typography>

                        <Typography variant="h5" className={classes.errorDesc}>
                            We couldn't find the page you are looking for.
                            <br />
                        </Typography>
                        <Button onClick={routeChange} color="primary" size="medium" variant='outlined'>
                            Back to Login
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                        <img src={error404} alt="error404" className={classes.imgStyle} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ErrorPage404;
