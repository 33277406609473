import CryptoJS from 'crypto-js';

const aesKeyHex = process.env.REACT_APP_AESKEY;
const ivHex = process.env.REACT_APP_AESIV;


// Function to decrypt AES-encrypted data
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return {
      status: false,
      data: null
    }
  }
  try {
    // Convert AES key and IV from hex to bytes
    const aesKey = CryptoJS.enc.Hex.parse(aesKeyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);

    // Create a WordArray from the hex-encoded encrypted data
    const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedData);

    // Decrypt the data using AES
    const decryptedWordArray = CryptoJS.AES.decrypt({ ciphertext: encryptedWordArray }, aesKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert the decrypted WordArray to a string
    const decryptedText = decryptedWordArray.toString(CryptoJS.enc.Utf8);

    // Attempt to parse the decrypted text as JSON
    const parsedData = JSON.parse(decryptedText);

    return parsedData;
  } catch (error) {
    return {
        status: false,
        data: null
    };
  }
};

// Function to encrypt data using CryptoJS
export const encryptData = (dataToEncrypt) => {
  try {
    // Convert AES key and IV from hex to bytes
    const aesKey = CryptoJS.enc.Hex.parse(aesKeyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);

    // Encrypt the data using AES encryption
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), aesKey, {
      iv: iv,
    }).toString();
    return { data: encryptedData };
  } catch (error) {
    return { msg: error };
  }
};
