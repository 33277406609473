// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const UNAUTHORIZED = '@auth/UNAUTHORIZED';
export const GET_USER = '@auth/GET_USER';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_RIGHT_SIDEBAR = '@customization/SET_RIGHT_SIDEBAR';
export const SET_ANNUAL_PAYMENT = '@customization/SET_ANNUAL_PAYMENT';
export const SET_RENEW_PLAN = '@customization/SET_RENEW_PLAN';
