import {  createBrowserRouter } from 'react-router-dom';

// project imports
import { dashboardRoutes } from './dashboardRoutes';
import { authRoutes } from './authRoutes';





//-----------------------|| ROUTING RENDER ||-----------------------//

const AppRoutes = createBrowserRouter([
    ...authRoutes,
    ...dashboardRoutes,

]);
export default AppRoutes;
