import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { gridSpacing } from 'store/constant';
import { SET_RIGHT_SIDEBAR } from 'store/actions';

// assets


// style constant
const useStyles = makeStyles((theme) => ({
    ScrollHeight: {
        height: '100vh',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            height: '100vh'
        }
    }
}));

const Customization = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const { RightDrawerToggle, RightDrawerType } = customization;

    // Handle right drawer
    const handleRightDrawerToggle = () => {
        dispatch({
            type: SET_RIGHT_SIDEBAR,
            payload: { RightDrawerToggle: !RightDrawerToggle, RightDrawerType: '' }
        });
    };


    return (
        <React.Fragment>
            {/* toggle button */}

            <Drawer
                anchor="right"
                onClose={handleRightDrawerToggle}
                open={RightDrawerToggle}
                PaperProps={{
                    sx: {
                        width: 438
                    }
                }}
            >
                <PerfectScrollbar component="div" className={classes.ScrollHeight}>
                    <Grid container spacing={gridSpacing} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                       
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </React.Fragment>
    );
};

export default Customization;
