import React from 'react';
import Div from 'ui-component/Div';
import { Typography } from '@mui/material';
import { ReactComponent as NodataIcon } from 'assets/images/icons/file-search.svg';
const NoData = ({ children, message, width, height, iconSvg }) => {
    if (children) return children;

    return (
        <Div sx={{ textAlign: 'center', p: 3, mt: 3 }}>
            {iconSvg ? <span>{iconSvg}</span> : <NodataIcon />}
            <Typography variant={'h5'} color={'text.secondary'} mt={1}>
                {message}
            </Typography>
        </Div>
    );
};

export default NoData;
