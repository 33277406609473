import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// project imports
import { store, persister } from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

// style + assets
import 'assets/scss/style.scss';
import 'toastify-js/src/toastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| REACT DOM RENDER  ||-----------------------//

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persister}>
                    <App />
            </PersistGate>
        </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
);
serviceWorker.unregister();
