import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import NavItem from './NavItem';

import { SSMenu } from 'menu-items/ssMenu';
import { DRSMenu } from 'menu-items/drsMenu';
import { MEMenu } from 'menu-items/meMenu';
import { MTMenu } from 'menu-items/mtMenu';
import { SHMenu } from 'menu-items/shMenu';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
    const [displayMenu, setDisplayMenu] = React.useState(DRSMenu);
    const account = useSelector((state) => state.account);
    const { user } = account;
    React.useEffect(() => {
        if (user?.department === 'M&T' && user?.user_type === 'staff') {
            setDisplayMenu(MTMenu);
        } else if (user?.department === 'M&E' && user?.user_type === 'staff') {
            setDisplayMenu(MEMenu);
        } else if (user?.department === 'SS' && user?.user_type === 'staff') {
            setDisplayMenu(SSMenu);
        } else if (user?.department === 'DRS' && user?.user_type === 'staff') {
            setDisplayMenu(DRSMenu);
        } else {
            setDisplayMenu(SHMenu);
        }
        // eslint-disable-next-line
    }, [user?.department, user?.user_type]);

    const navItems = displayMenu?.children?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'item':
                return <NavItem key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
