import { axiosInstance, removeToken,getAuthToken} from './config';
import ToastifyMsg from 'utils/toastify';
import { encryptData, decryptData } from 'utils/CryptoJS';
import { GET_USER } from 'store/actions';


const authServices = {};

authServices.getCurrentUser = () => async (dispatch) => {
    try {
        const { data } = await axiosInstance.get('auth/user');
        const decrypted_data = decryptData(data?.data);
        dispatch({ type: GET_USER, payload: decrypted_data?.data?.user });
        return decrypted_data;
    } catch (err) {
        const decrypted_data = decryptData(err?.response?.data?.data);
        return decrypted_data;
    }
};


authServices.logout = async () => {
    const token = { refresh_token: getAuthToken() };
    try {
        const { data } = await axiosInstance.post('auth/logout', token);
        removeToken();
        ToastifyMsg('success', 'Logged out');
        return data;
    } catch (err) {
        return err;
    }
};

authServices.changePassword = async (value) => {
    try {
        const { data } = await axiosInstance.post('auth/change_password', {
            old_password: value.password,
            new_password: value.password2
        });
        ToastifyMsg('success', 'Success');
        return data;
    } catch (e) {
        ToastifyMsg('error', 'Error');
        return e?.response?.data?.data;
    }
};
authServices.orgRegister = async (values) => {
    try {
        const { data } = await axiosInstance.post('auth/org/register', values);
        ToastifyMsg('success', 'Kindly check your email.');
        return data;
    } catch (e) {
        ToastifyMsg('error', 'Error');
        return e?.response?.data?.data;
    }
};
authServices.staffRegister = async (values) => {
    try {
        const { data } = await axiosInstance.post('auth/register', values);
        ToastifyMsg('success', 'Kindly check your email.');
        return data;
    } catch (e) {
        ToastifyMsg('error', 'Error');
        return e?.response?.data?.data;
    }
};
authServices.activateAccount = async (id) => {
    try {
        const { data } = await axiosInstance.get(`auth/activate_email/${id}`, {
        id: id,
      })
        ToastifyMsg('success', 'Account Activated');
        return data;
    } catch (e) {
        ToastifyMsg('error', 'Token has expired. Please try again.');
        return e?.response?.data?.data;
    }
};
export default authServices;
