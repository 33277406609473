import React from 'react';


// material-ui
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

// assets
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';

// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.primary.light,
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.primary.main,
            borderRadius: '50%',
            top: '65px',
            right: '-150px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.primary.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    tagLine: {
        color: theme.palette.grey[900],
        opacity: 0.6
    },
    button: {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    item: {
        cursor: 'pointer'
    },
    icon: {
        color: theme.palette.primary.main
    }
}));

//-----------------------|| PROFILE MENU - UPGRADE PLAN CARD ||-----------------------//

const HelpCard = () => {
    const classes = useStyles();

    const verified_token = localStorage.getItem('verified_token');
    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Other App</Typography>
                    </Grid>
                    <Grid item>
                        <List>
                            <ListItem className={classes.item}>
                                <ListItemButton href={`https://demurrage.shipperscouncil.gov.ng/auth/?token=${verified_token}`} target='_blank'>
                                    <ListItemIcon>
                                        <DashboardCustomizeOutlinedIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary="CRD Portal" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HelpCard;
