

const config = {
    basename: '',
    defaultPath: '/admin/business/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
