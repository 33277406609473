import React, { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const VoyagePage = Loadable(lazy(() => import('views/voyage')));
const KpiPage = Loadable(lazy(() => import('views/kpi')));
const TariffPage = Loadable(lazy(() => import('views/tariff')));
const EventsPage = Loadable(lazy(() => import('views/event')));
const TaskPage = Loadable(lazy(() => import('views/task')));
const ReportPage = Loadable(lazy(() => import('views/report')));
const MinutePage = Loadable(lazy(() => import('views/minutes')));
const ChatPage = Loadable(lazy(() => import('views/chat')));
const FolderPage = Loadable(lazy(() => import('views/e_file')));
const FreightPage = Loadable(lazy(() => import('views/freight')));
const SSKPIPage = Loadable(lazy(() => import('views/sskpi')));
const AnalyticPage = Loadable(lazy(() => import('views/kpi-analytics')));

export const dashboardRoutes = [
    {
        path: '/dashboard/division',
        element: (
            <AuthGuard>
                <MainLayout>
                    <DashboardPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/voyage',
        element: (
            <AuthGuard>
                <MainLayout>
                    <VoyagePage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/kpi',
        element: (
            <AuthGuard>
                <MainLayout>
                    <KpiPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/tariff',
        element: (
            <AuthGuard>
                <MainLayout>
                    <TariffPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/events',
        element: (
            <AuthGuard>
                <MainLayout>
                    <EventsPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/events/:eventID',
        element: (
            <AuthGuard>
                <MainLayout>
                    <EventsPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/tasks',
        element: (
            <AuthGuard>
                <MainLayout>
                    <TaskPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/tasks/:taskID',
        element: (
            <AuthGuard>
                <MainLayout>
                    <TaskPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/reports',
        element: (
            <AuthGuard>
                <MainLayout>
                    <ReportPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/reports/:reportID',
        element: (
            <AuthGuard>
                <MainLayout>
                    <ReportPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/minutes',
        element: (
            <AuthGuard>
                <MainLayout>
                    <MinutePage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/minutes/:minuteID',
        element: (
            <AuthGuard>
                <MainLayout>
                    <MinutePage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/chat',
        element: (
            <AuthGuard>
                <MainLayout>
                    <ChatPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/chat/:chatID',
        element: (
            <AuthGuard>
                <MainLayout>
                    <ChatPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/folders',
        element: (
            <AuthGuard>
                <MainLayout>
                    <FolderPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/folders/:folderID/:folderName',
        element: (
            <AuthGuard>
                <MainLayout>
                    <FolderPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/airfreight',
        element: (
            <AuthGuard>
                <MainLayout>
                    <FreightPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/sskpi',
        element: (
            <AuthGuard>
                <MainLayout>
                    <SSKPIPage />
                </MainLayout>
            </AuthGuard>
        )
    },
    {
        path: '/dashboard/division/kpi-analytics',
        element: (
            <AuthGuard>
                <MainLayout>
                    <AnalyticPage />
                </MainLayout>
            </AuthGuard>
        )
    }
];
