import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import NavMotion from 'layout/NavMotion';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ErrorPage from './error-page';

// pages

const AuthLogin = Loadable(lazy(() => import('views/auth/login')));
const PassPinPage = Loadable(lazy(() => import('views/auth/passwordChange')));
const ForgotPassword = Loadable(lazy(() => import('views/auth/forgotPassword')));
const ResetPassword = Loadable(lazy(() => import('views/auth/resetPassword')));
const Register = Loadable(lazy(() => import('views/auth/register')));
const StaffRegister = Loadable(lazy(() => import('views/auth/staffReg')));
const EmailActive = Loadable(lazy(() => import('views/auth/emailActivate')));
const Reverify = Loadable(lazy(() => import('views/auth/reVerify')));
export const authRoutes = [
    {
        path: '/',
        element: <Navigate to={`/auth/login`} />,
        errorElement: <ErrorPage />
    },
    {
        path: '/auth/login',
        element: (
            <GuestGuard>
                <MinimalLayout>
                    <NavMotion>
                        <AuthLogin />
                    </NavMotion>
                </MinimalLayout>
            </GuestGuard>
        )
    },
    {
        path: '/auth/forgotPassword',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <ForgotPassword />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/rsd/register',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <Register />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/staff/register',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <StaffRegister />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/resetPassword/:token',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <ResetPassword />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/activate/:token',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <EmailActive />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/re-verify',
        element: (
            <MinimalLayout>
                <NavMotion>
                    <Reverify />
                </NavMotion>
            </MinimalLayout>
        )
    },
    {
        path: '/auth/settings/password',
        element: (
            <AuthGuard>
                <MainLayout>
                    <PassPinPage />
                </MainLayout>
            </AuthGuard>
        )
    }
];
