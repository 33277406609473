import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// third-party
import clsx from 'clsx';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import { SET_MENU } from 'store/actions';
import Customization from './../Customization';
import { mainLayoutStyle } from 'useStyles/layout';
// assets



const MainLayout = ({ children }) => {
    const classes = mainLayoutStyle();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    React.useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="relative"
                color="inherit"
                elevation={0}
                className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
            >
                <Toolbar>
                    <Header
                        handleLeftDrawerToggle={handleLeftDrawerToggle}
                        open={leftDrawerOpened}
                    />
                </Toolbar>
            </AppBar>

            <Box display="flex" flex="1" style={{ overflowY: 'scroll' }}>
                {/* drawer */}
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

                {/* main content */}
                <main
                    className={clsx([
                        classes.content,
                        {
                            [classes.contentShift]: leftDrawerOpened
                        }
                    ])}
                >
                    {/* <Main open={leftDrawerOpened}> */}
                    <div>{children}</div>
                    {/* </Main> */}
                </main>
                <Customization />
            </Box>
        </div>
    );
};

export default MainLayout;
