import React from 'react';

// material-ui
import {
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Tooltip
} from '@mui/material';

// project import
import { notificationItemStyle } from 'useStyles/notification';
import { timeSince } from 'utils/formatHelpers';
// assets
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//

const NotificationList = ({ notifications, userId, markSingleAsRead }) => {
    const classes = notificationItemStyle();
    return (
        <List className={classes.navContainer}>
            {notifications?.map((data) => (
                <>
                    <Tooltip
                        disableFocusListener
                        disableTouchListener
                        placement="top"
                        title="mark as read"
                        onClick={() => markSingleAsRead(data?.notify_ID)}
                    >
                        <div
                            className={`${
                                data?.read_by?.includes(userId)
                                    ? classes.listRead
                                    : classes.listUnread
                            }`}
                        >
                            <ListItem alignItems="center" className={classes.listItem}>
                                <ListItemAvatar>
                                    <Avatar className={classes.listAvatarPrimary}>
                                        <MarkEmailUnreadOutlinedIcon fontSize="1.5rem" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1">
                                            {data?.notify_dept} Department
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <Grid container direction="column" className={classes.listContainer}>
                                <Grid item xs={12} className={classes.paddingBottom}>
                                    <Typography variant="subtitle2">{data?.title}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Tooltip>
                    <Divider className={classes.listDivider} />
                </>
            ))}
        </List>
    );
};

export default NotificationList;
