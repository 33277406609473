import Toastify from 'toastify-js';

const ToastifyMsg = (status, msg) => {
    status === 'success'
        ? Toastify({
              text: msg,
              duration: 5000,
              style: {
                  background: '#033e1c'
              }
          }).showToast()
        : Toastify({
              text: msg,
              duration: 5000,
              style: {
                  background: '#EB3145'
              }
          }).showToast();
};
export default ToastifyMsg; 