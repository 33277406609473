import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        // backgroundColor: theme.palette.errorPageBg.dark,
        width: '100%',
        height: '100vh',
        maxWidth: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    errorCode: {
        textAlign: 'left',
        color: theme.palette.primary.dark,
        marginTop: '50px',
        marginLeft: '25px',
        fontFamily: 'Avenir',
        fontWeight: '500',
        fontSize: '10em',
        display: 'block'
    },

    errorDesc: {
        marginLeft: '25px',
        fontWeight: '200',
        marginBottom: '10px',
        fontSize: '22px',
        display: 'block',
        color: theme.palette.grey[900]
    },

    errorMsg: {
        marginLeft: '25px',
        fontWeight: '200',
        fontSize: '16px',
        color: theme.palette.primary.dark
    },

    imgStyle: {
        width: 500,
        height: 400,
        marginTop: '10px',
        position: 'relative',
        right: -70
    }
}));

export default useStyles;
