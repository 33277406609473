import React from 'react';

// material-ui
import { Box, Stack, Typography, ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// project imports
import config from 'config';
import erppIMG from 'assets/images/ERPP.jpg';
import Div from 'ui-component/Div';

const ErppSection = () => {
    const account = useSelector((state) => state?.account);
    const { user } = account;
    return (
        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
            <Stack direction="row" justifyContent="space-between">
                <ButtonBase disableRipple component={Link} to={config.defaultPath} sx={{ ml: 1.2 }}>
                    <img src={erppIMG} alt="erpp-img" height={55} />
                </ButtonBase>
                <Div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography
                        variant="h4"
                        component="h2"
                        textAlign="center"
                        sx={{
                            ml: 4
                        }}
                    >
                        {user?.department}
                    </Typography>
                </Div>
            </Stack>
        </Box>
    );
};

export default ErppSection;
