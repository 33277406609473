import dayjs from 'dayjs';

export const getDayPeriods = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    // Determine the time of day
    let timeOfDay;

    if (currentHour >= 0 && currentHour < 12) {
        timeOfDay = 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
        timeOfDay = 'Good Afternoon';
    } else {
        timeOfDay = 'Good Evening';
    }
    return timeOfDay;
};


export const timeSince = (dateString) => {
    const calcDate = new Date(dateString);
    let seconds = Math?.floor((new Date() - calcDate) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + 'y ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + 'm ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + 'd ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + 'h ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + 'm ago';
    }
    return Math.floor(seconds) + 's ago';
};



export const getDateElements = (date) => {
    const dateString = dayjs(date).format('dddd, MMMM DD YYYY, hh:mm A');
    const dateSections = dateString.split(',');
    const day = dateSections[0];
    const time = dateSections[2];
    const datePart = dateSections[1].trim().split(' ');
    return {
        day,
        time,
        date: {
            dateString: dateSections[1],
            month: datePart[0],
            date: datePart[1],
            year: datePart[2]
        }
    };
};
