import axios from 'axios';
import { store } from 'store';
import { UNAUTHORIZED } from 'store/actions';

const SERVER_API = process.env.REACT_APP_BASE_API;

let axiosInstance = axios.create({
    baseURL: SERVER_API,
    timeout: 6000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

export const getAuthToken = () => {
    let data = localStorage.getItem('nsc-account');
    let tokenData = JSON.parse(data);
    return tokenData?.token.replaceAll('"', '');
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            config.headers['Authorization'] = `Token ${token}`;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (
            error.response.data.code === 'token_not_valid' ||
            error.response.status === 401 ||
            error.response.statusText === 'Unauthorized' ||
            error.response.data.detail === 'Authentication credentials were not provided.'
        ) {
            removeToken();
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);
export const removeToken = () => {
    delete axiosInstance.defaults.headers.common['Authorization'];
    localStorage.removeItem('verified_token');
    store.dispatch({ type: UNAUTHORIZED });
};
export const setAuthToken = (token) => {
    if (token) {
        if (axiosInstance?.defaults?.headers?.common['Authorization']) {
            axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
        }
    } else {
        removeToken();
    }
};

export { axiosInstance };
