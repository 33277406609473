import React from 'react';

// material-ui
import { useTheme } from '@mui/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import { useSelector } from 'react-redux';

// project imports
import MenuList from './MenuList';
import HelpCard from './HelpCard';
import LogoSection from '../LogoSection';
import { sideBarStyles } from 'useStyles/layout';

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const classes = sideBarStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const account = useSelector((state) => state?.account);
    const { user } = account;
    const showCRD = ['Shipping Company', 'Banking', 'CBN', 'Logistics Service Provider', 'Cargo Consolidator'];
    const department = ['DRS', 'M&T'];

    const drawer = (
        <React.Fragment>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <div className={classes.boxContainer}>
                    <LogoSection />
                </div>
            </Box>
            <BrowserView>
                <PerfectScrollbar component="div" className={classes.ScrollHeight}>
                    <MenuList />
                    {showCRD.includes(user?.org_user?.subject)? <HelpCard />:
                        department.includes(user?.department) ? <HelpCard /> : null }
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                     {showCRD.includes(user?.org_user?.subject)? <HelpCard />:
                        department.includes(user?.department) ? <HelpCard /> : null }
                </Box>
            </MobileView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

export default Sidebar;
