import React from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

// routing
import AppRoutes from './routes';
import authServices from 'services/auth-services'
// defaultTheme
import theme from './themes';

//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);
    React.useEffect(() => {
        const sessionTimeout = setTimeout(() => {
            authServices.sessionTimeout()
        }, 3600000);
        document.addEventListener('mousemove', () => {
            clearTimeout(sessionTimeout);
        });
        return () => {
            clearTimeout(sessionTimeout);
        };
    })
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <RouterProvider router={AppRoutes} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
