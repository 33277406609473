import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import reducer from './reducer';

//-----------------------|| REDUX - MAIN STORE ||-----------------------//

// const store = createStore(reducer);
const middlewares = [thunk];
const composeEnhancers =
    process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const store = createStore(
    reducer, // Combine all your reducers here
    composeEnhancers(applyMiddleware(...middlewares))
);
const persister = persistStore(store);

export { store, persister };
