import React from 'react';

// material-ui
import { useTheme } from '@mui/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardActions,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import NoDataPlaceholder from 'ui-component/NoData';
import { ReactComponent as NoNotifications } from 'assets/images/icons/notification.svg';
import { notifyServices } from 'services/notification-services';
import { queryClient } from 'index';
import Div from 'ui-component/Div';
// assets
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// style constant
import { notificationStyles } from 'useStyles/notification';

//-----------------------|| NOTIFICATION ||-----------------------//

const NotificationSection = () => {
      const account = useSelector((state) => state?.account);
      const { user } = account;
      const user_id = user?.user_id;
    const data = useQuery({
        queryKey: ['notifications'],
        queryFn:() => notifyServices.NotificationList(user_id),
        staleTime: 7000
    });

    const classes = notificationStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const reFreshQuery = () => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] });
    };
  
    // mark all as read notification
    const markAllFormMutation = useMutation({
        mutationFn: notifyServices.markAllAsRead
    });
    const markAllAsRead = () => {
        markAllFormMutation.mutate(user_id, {
            onSuccess: () => {
                reFreshQuery();
            }
        });
    };

    // mark as read single notification
    const markSingleFormMutation = useMutation({
        mutationFn: notifyServices.markAsRead
    });
    const markSingleAsRead = (notify_ID) => {
        const data = { user_id, notify_ID };
        markSingleFormMutation.mutate(data, {
            onSuccess: (data) => {
                reFreshQuery();
            }
        });
    };

    const allNotifications = data?.data?.results;
    const notifications =  allNotifications;
    const notificationMsg = 'You have no unread notifications';

    return (
        <Box >
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => {
                            handleToggle();
                            reFreshQuery();
                        }}
                        color="inherit"
                    >
                        <NotificationsNoneOutlinedIcon fontSize="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
            style={{ zIndex: 5000  }}
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <div className={classes.bodyPPacing}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Typography variant="h4">
                                                                    Notifications
                                                                </Typography>
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        notifications?.length >= 1
                                                                            ? notifications?.length
                                                                            : 0
                                                                    }
                                                                    className={
                                                                        classes.notificationChip
                                                                    }
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            {notifications?.length >= 1 ? (
                                                <Grid item xs={12}>
                                                    <PerfectScrollbar
                                                        className={classes.ScrollHeight}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}></Grid>
                                                            <Grid item xs={12} p={0}>
                                                                <Divider
                                                                    className={classes.divider}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <NotificationList
                                                                    notifications={notifications}
                                                                    reFresheQuery={reFreshQuery}
                                                                    markSingleAsRead={
                                                                        markSingleAsRead
                                                                    }
                                                                    userId={user_id}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </PerfectScrollbar>
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}></Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Div className={classes.msgContainer}>
                                                                <NoDataPlaceholder
                                                                    message={notificationMsg}
                                                                    iconSvg={<NoNotifications />}
                                                                />
                                                            </Div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </CardContent>
                                  
                                        {notifications.length >=5 && <CardActions className={classes.cardAction}>
                                            <Typography
                                                variant="subtitle2"
                                                color="primary"
                                                onClick={() => markAllAsRead()}
                                                className={classes.markAll}
                                            >
                                                Mark all as read
                                            </Typography>
                                        </CardActions>}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default NotificationSection;
