import { axiosInstance } from './config';
import axios from 'axios';
import { encryptData, decryptData } from 'utils/CryptoJS';

export const notifyServices = {};

notifyServices.NotificationList = async (user_id) => {
    try {
        const {data} = await axiosInstance.get(`notify/${user_id}`);
        return data;
    } catch (err) {
        return err?.response?.data?.data;
    }
};
notifyServices.markAsRead = async (notify) => {
    try {
        const { data } = await axiosInstance.patch(`notify/${notify.user_id}`, {
            notify_id: notify.notify_ID
        });
        return data;
    } catch (err) {
        return err?.response?.data?.data;
    }
};
notifyServices.markAllAsRead = async (user_id) => {
    try {
        const { data } = await axiosInstance.post(`notify/${user_id}`);
        return data;
    } catch (err) {
        return err?.response?.data?.data;
    }
}

